import logo from 'assets/images/petcard_logo.svg';
import logoPayoff from 'assets/images/petCards_logo_portal.png';
import favicon from 'assets/images/bkz_favicon.png';
import favicon16 from 'assets/images/petcards-favicon-16x16.png';
import defaultConfigs from './default';

const AWS = {
  aws_project_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint:
    'https://djf54hipijbd5olstejq4uarze.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'userapi',
      endpoint: 'https://6lsixzsvo5.execute-api.eu-west-1.amazonaws.com/pet',
      region: 'eu-west-1',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-west-1:98cf370d-30b5-4e71-85e9-268d4743fb1c',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_buV55g766',
  aws_user_pools_web_client_id: '76s0gsjtjpp1sv9ln6nes3rpjn',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'clinicardstorage0d91c-pet',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_public_images_bucket: 'publicarticleimages0d91cc-pet',
  aws_public_images_bucket_region: 'eu-west-1',
};

const defaultCarepathCards = [];

export default {
  ...defaultConfigs,
  APP_TITLE: 'Petcards',
  CONTENT_LANGUAGE: 'nl',
  CONTENT_LANGUAGES: ['nl', 'en', 'fr', 'de'],
  ENABLE_ARTICLES: true,
  ENABLE_CAREPATH: true,
  ENABLE_CARDS: true,
  ENABLE_MANAGEMENT: true,
  ENABLE_CLINICARDS: true,
  ENABLE_PERSONAL_MESSAGES: true,
  AWS,
  logo,
  logoPayoff,
  defaultCarepathCards,
  ENABLE_LIST_CHILD_NAME: true,
  favicon,
  favicon16,
  DEFAULTCARDS: [
    'zds11we-83fa-47d2-zzdd-bbb5d3753450',
    'sw23rd12sd-83fa-47d2-x2vc-bbb5d3753450',
    '94cd8ea4-82f6-4ac4-b58d-be7b386ca41e',
    'de673886-5669-4e0d-b142-6fda146d2334',
    '173e478c-057b-493a-b797-9dbc4049ae41',
    'dfe43zx1-83fa-47d2-ds3e-bbb5d3753450',
    '2as21d32-83fa-47d2-a23f-bbb5d3753450',
  ],
  INSIGTHSCARDORDER: [
    'pet_medication',
    'pet_drink',
    'pet_weight',
    'pet_gen_health',
    'vas_painscore',
    'pet_eating',
    'pet_eating_quantity',
    'pet_activity',
  ],
  ENABLE_USAGE_ACTIVITY: true,
  ENABLE_SIGNALING: false,
  ENABLE_REFERENCE: false,
  ENABLE_STACKED_CAREPATH: true,
  ENABLE_PERMISSIONS: true,
  ENABLE_ORGANISATION_LEVEL: true,
  ENABLE_CHILD_PROFILE: true,
  ENABLE_ORGANISATION_DETAILS: true,
  ENABLE_ORGANISATION_MULTIPLE: true,
  ENABLE_STICKY_CAREPATHITEM: true,
  ENABLE_CAREPATHS_ARTICLES: false,
  ENABLE_ACTIVATIONPERIOD_CAREPATHITEM: false,
  ENABLE_INSTANCE_SPECIC_NAMING: true,
  ENABLE_BIRTH_INFO: false,
  VISUALISE_INACTIVE_USERS: true,
  ENABLE_ARTICLE_EXTERNALLINK: true,
  ENABLE_VPK: true,
  REMOVE_GENDER_FROM_ONBOARDING: true,
  ENABLE_HIDE_PATIENT_BIRTHDATE: true,
  REMOVE_CUSTOMER_NUM_FROM_ONBOARDING: true,
  ARTICLE_AUTOMATIC_TRANSLATION: true,
  ENABLE_RELATION_CAREPATH_CHILD: true,
  ENABLE_NEW_ORGANIZARION_FORM: true,
  NEW_ARTICLE_LISTVIEW: true,
  ENABLE_COPY_SCRIPT: 'fec26292-cd08-4299-b714-8e0e9fcce9c0',
  ENABLE_SORT_LIST: true,
  ENABLE_REPEAT_CAREPATH: true,
};
